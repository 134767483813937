:root {
  --global-color-dark: #010440;
  --global-color-red: #F21D1D;
  --global-color-green: #48B509;
  --global-color-lightgrey: #D9D9D9;
  --global-color-lightergry: #E6E6E6;
}

.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

main {
  padding: 30px;
  text-align: center;
  width: 100%;
  color: var(--global-color-dark);
}

a {
  color: var(--global-color-dark);
  text-decoration: none;
}

/** customize bootstrap classes */
.btn-primary {
  background-color: var(--global-color-dark);
  border-color: var(--global-color-dark);;
}

.btn-danger {
  background-color: var(--global-color-red);
  border-color: var(--global-color-red);
}

/* SIDEBAR STYLING */

.side-bar {
  display: flex;
  flex-direction: column;
  width: 310px;
  height: 100vh;
  box-shadow: 0px 5px 20px 0px rgb(7 25 60 / 50%);
}

.side-bar-body {
  width: 260px;
  padding: 5px 15px;
  font-size: 12pt;
  font-family: 'Lato', sans-serif;
  color: var(--global-color-dark);
}

.side-bar-body h3 {
  font-size: 16pt;
  margin-bottom: 15px;
  color: var(--global-color-red);
}

.api-link {
  margin-top: 20px;
  font-size: 12pt;
  transition: all .1s ease;
  padding-left: 10px;
  border-left: 3px solid var(--global-color-red);
}

.api-link:hover {
  cursor: pointer;
  border-left: 12px solid var(--global-color-red);
  transition: all .1s ease;
}

.api-link .active {
  color: var(--global-color-red);
}

#api-data {
  width: 90vw;
  padding-left: 30px;
  padding-top: 30px;
  overflow-y: scroll;
}

.side-bar-header {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: whitesmoke;
  border-bottom: 5px solid var(--global-color-red);
  font-family: 'Righteous', cursive;
  text-align: center;
}

.side-bar-header h1 {
  color: var(--global-color-dark);
  margin-top: 20px;
  font-size: 20pt;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.side-bar-header h3 {
  font-size: 10pt;
  font-family: 'Lato', sans-serif;
}

.side-bar-header img {
  display: block;
  width: 220px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 30px 0px;
}

.side-bar-body button {
  padding: 8px 20px 8px 20px;
}

/* SWAGGER STYLING */

.swagger-ui .info {
  background-color: white;
  padding: 20px;
  border-bottom: 1px solid #c1c6d1;
}

.swagger-ui .info .description p {
  color: black;
}

.swagger-ui .info .description .markdown {
  color: black;
}

.swagger-ui .scheme-container {
  background-color: rgba(1, 1, 1, 0);
  box-shadow: 0 0 0 0;
}

.swagger-ui .opblock-tag {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #d4be7d;
}

.swagger-ui .op-block {
  border-radius: 0;
}

.swagger-ui section.models {
  border: 1px solid #d4be7d;
  border-radius: 0;
}

.swagger-ui section.models .model-container {
  background-color: white;
  border-radius: 0;
}

.swagger-ui section.models .model-container:hover {
  background-color: whitesmoke;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}